import Box from "@mui/material/Box"
import useWindowSize from "../../_hooks/useWindowSize";

/** SSR component which will change width of the page depending on: 
 * 1 - template page settings 
 * 2 - is mobile
 * @returns component with children either boxed or full width depending on props
 */

{/* <Box className={ (layout == 'box' && !isMobile ) || (!isMobile) ? 'boxed-padding' : ''}></Box> */}

const BoxContainerV2 = (props) => {
    const {isMobile} = useWindowSize();
    
    const {
        children,  
        className = "", 
        mobileClasses = "", 
        template_page_settings = {
            layout: 'box'
        },
        custom_layout = "",
        ...other
    } = props;


    const boxedPaddingClass = ((custom_layout == 'box') || (template_page_settings.layout == 'box' && !isMobile)) ? 'boxed-padding' : mobileClasses;
    // const boxedPaddingClass = (template_page_settings.layout == 'box' && !isMobile) ?
    //     'boxed-padding'
    //  : 
    //  mobileClasses;

    return ( 
        <Box
            {...other}
            className={`${className} ${boxedPaddingClass}`}
        > 
            {children}
        </Box> 
    )
}

export default BoxContainerV2